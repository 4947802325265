import { useIntl } from 'react-intl';

export const Translate = ({ value, className }) => {
  if (!value) return '';
  const intl = useIntl();
  const formattedMsg = intl.formatMessage({ id: value });
  if (formattedMsg === 'NULL') return '';
  return <span className={className}>{formattedMsg}</span>;
};

const replaceVariables = (message, variables) => {
  return message.replace(/\{(\w+)\}/g, (match, key) => variables[key] || match);
};

export const TranslateWithValues = (key, translateValues = {}) => {
  const intl = useIntl();
  const formattedMsg = intl.formatMessage({ id: key }, translateValues);
  // Variables not replaced with html contents- TO-Check
  return replaceVariables(formattedMsg, translateValues);
};
