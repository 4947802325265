import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { getWorldpayTxnStatus } from '../../../apis';
import { RawHtmlContent } from '../../../components/Format';
import { Translate } from '../../../i18n/translate';
import { useScript } from '../../../utils/helper';
import { STATUSES } from '../constants';

const WorldPay = ({ iframeUrl, locale, showReceipt, goToChoosePayments }) => {
  const [scriptReady, setScriptReady] = useState(false);
  const [reasonCode, setReasonCode] = useState('');
  const [dealRef, setDealRef] = useState('');
  const [timeUp, setTimeUp] = useState(true);
  const [enableCardError, setEnableCardError] = useState(false);
  const timeoutRef = useRef(null);
  const countdownRef = useRef(120);
  const callbackInvoked = useRef(false);
  const intl = useIntl();

  useScript(
    'https://payments.worldpay.com/resources/hpp/integrations/embedded/js/hpp-embedded-integration-library.js',
    () => {
      setScriptReady(true);
    },
  );

  useEffect(() => {
    if (scriptReady && iframeUrl) {
      let customOptions = {
        //   iframeHelperURL: location.protocol + '//' + location.host + GPFS_CONTEXT + '/helper.html',
        iframeHelperURL: 'https://qa1.students.convera.com/geo-buyer/helper.html', //TODO: What do to about helper URL?
        url: iframeUrl,
        type: 'iframe',
        inject: 'immediate',
        target: 'convera-payment',
        accessibility: true,
        debug: false,
        language: locale,
        country: 'gb',
        preferredPaymentMethod: 'VISA-SSL',
        resultCallback: function (result) {
          if (callbackInvoked.current) return;
          callbackInvoked.current = true;
          if (result.order) {
            setTimeUp(true);
            setEnableCardError(false);
            if (result.order.status === 'success') {
              showReceipt(STATUSES.SUCCESS); //Success receipt
            } else if (result.order.status === 'cancelled_by_shopper') {
              goToChoosePayments();
            } else if (result.order.status === 'failure' && result.order.orderKey) {
              setDealRef(result.order.orderKey.split('^')[1]);
              setTimeUp(false);
            } else {
              showReceipt(STATUSES.FAILED); //failure receipt
            }
          }
        },
      };
      setupWPLC(customOptions);
    }
  }, [scriptReady, iframeUrl]);

  useEffect(() => {
    if (timeUp) {
      return;
    }
    const fetchTransactionStatus = async () => {
      let response = await getWorldpayTxnStatus(dealRef);
      if (response.success) {
        let txnStatusResponse = response.data;
        setEnableCardError(txnStatusResponse.data.callBackReceivedWithDeclined);
        setReasonCode(txnStatusResponse.data.returnCode);
      }
    };

    const updateCountDown = () => {
      countdownRef.current--;
      if (countdownRef.current > 0 && !timeUp && !enableCardError) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(updateCountDown, 10000);
        fetchTransactionStatus();
      } else if (countdownRef.current === 0 || enableCardError) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        setTimeUp(true);
      }
    };

    updateCountDown();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [timeUp, enableCardError]);

  const isI18nExist = () => {
    const message = intl.formatMessage({ id: 'JS_WORLDPAY_' + reasonCode + '_REASON_LABEL' });
    return !!message && message != 'JS_WORLDPAY_' + reasonCode + '_REASON_LABEL';
  };

  const setupWPLC = (options) => {
    if (typeof WPCL !== 'undefined') {
      let libraryObject = new WPCL.Library();
      libraryObject.setup(options);
    } else {
      setTimeout(function () {
        setupWPLC(options);
      }, 300);
    }
  };

  return (
    <>
      {enableCardError && isI18nExist() && (
        <div id='card-error'>
          <div className='worldpay-dialog-box max-w-[750px] m-auto mb-3'>
            <div className='font-bold text-lg text-wpWarning1'>Transaction Declined</div>
            <div className='mb-2'>
              <Translate
                className='text-wpWarning2'
                value={'JS_WORLDPAY_' + reasonCode + '_REASON_LABEL'}
              />
            </div>
            <div className='text-wpWarning2 wp-override'>
              <RawHtmlContent htmlContent={'JS_WORLDPAY_' + reasonCode + '_REASON_MESSAGE'} />
            </div>
          </div>
        </div>
      )}
      <slot id='convera-payment'></slot>
    </>
  );
};

export default WorldPay;
