import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CircularProgress } from '@mui/material';
import { useCallback, useEffect, useRef } from 'react';
import { getTxnStatus } from '../../apis';
import { PAYMENT_DEAL_STATUSES, STATUSES } from './constants';
import './paymentPartnerCheckout.scss';

const findReceiptStatus = (paymentType, transactionStatus) => {
  const statuses = PAYMENT_DEAL_STATUSES[paymentType] || {};
  if (statuses.SUCCESS?.includes(transactionStatus)) return STATUSES.SUCCESS;
  if (statuses.FAILED?.includes(transactionStatus)) return STATUSES.FAILED;
  if (statuses.PROCESSING?.includes(transactionStatus)) return STATUSES.PROCESSING;
  return '';
};

const PaymentPartnerCheckout = ({
  session,
  showReceipt,
  extURL,
  starPolling = true,
  submitPspForm = true,
  showMessage = true,
  pspFormOnTarget = '_blank',
}) => {
  const formRef = useRef(null);
  const { pspConnectionUrl, httpMethod, pspData, dealReference } = session.pledge;
  const { paymentType } = session.selectedQuote;
  const { pollingOnRedirect, id: paymentTypeId } = paymentType;

  const fetchTransactionStatus = useCallback(
    async (intervalId) => {
      const response = await getTxnStatus({ dealReference, paymentType: paymentTypeId });
      if (response.success) {
        const { transactionStatus, notificationReceived } = response.data.data;
        const status = findReceiptStatus(paymentTypeId, transactionStatus);
        if (notificationReceived && status) {
          clearInterval(intervalId); // Stop polling if we have a final status
          showReceipt(status);
        }
      }
    },
    [dealReference, paymentTypeId, showReceipt],
  );

  useEffect(() => {
    if (!pollingOnRedirect && submitPspForm) {
      if (pspConnectionUrl) formRef.current?.submit();
      return;
    }
    if (starPolling) {
      let countdown = 300;
      const intervalId = setInterval(async () => {
        countdown--;
        if (countdown % 5 === 0) await fetchTransactionStatus(intervalId);
        if (countdown <= 0) {
          clearInterval(intervalId);
          showReceipt(STATUSES.PROCESSING);
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [pollingOnRedirect, fetchTransactionStatus]);

  return (
    <div className='pt-3 pb-3 max-w-[750px] m-auto'>
      <div className='mt-1 mb-2'>
        <h4 className='text-16-bold mt-3'></h4>
        {!showMessage && (
          <CircularProgress
            className='absolute z-50 overflow-hidden left-0 right-0 m-auto'
            style={{
              top: '215px',
            }}
          />
        )}
        {showMessage && (
          <div className='ppc-info-container'>
            <InfoOutlinedIcon className='mr-4' />
            <ul>
              <li>
                <b>Please complete your payment in the new window that has automatically opened.</b>{' '}
                After completing your payment, return to this screen to view your receipt.
                <br />
                {(extURL || (!pollingOnRedirect && pspConnectionUrl)) && (
                  <p>
                    If you have a pop-up blocker enabled, please{' '}
                    <a
                      className='a-bold mr-0.5'
                      href={extURL || '#'}
                      target={extURL ? '_blank' : ''}
                      rel={extURL ? 'noreferrer' : ''}
                      onClick={extURL ? null : () => formRef.current?.submit()}
                    >
                      click here
                    </a>{' '}
                    to open the new window to complete your payment.
                  </p>
                )}
              </li>
            </ul>
          </div>
        )}

        {!extURL && pspConnectionUrl && (
          <form
            ref={formRef}
            id='pspForm'
            target={pspFormOnTarget}
            action={pspConnectionUrl}
            method={httpMethod}
          >
            {Object.entries(pspData).map(([name, value], index) => (
              <input key={index} type='hidden' name={name} value={value} />
            ))}
          </form>
        )}
      </div>
    </div>
  );
};

export default PaymentPartnerCheckout;
