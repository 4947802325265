import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import DocUploader from '../../components/DocumentUploader';
import DocUploadInfo from '../../components/DocumentUploader/docUploadInfo';
import {
  ADDRESS_PROOF_DOC_OPTIONS_INR_BANK,
  ADDRESS_PROOF_DOC_OPTIONS_VP_BANK,
} from './payerHelpers';

import Select from '@mui/material/Select';
import DatePicker from '../../components/DatePicker';
import InputWithLabel from '../../components/InputWithLabel';
import { Translate } from '../../i18n/translate';

const AddressProofSelectionComponent = ({
  addressProof,
  setAddressProof,
  addressProofOptions,
  handleAddressProofChange,
  selectAddressProofLabel,
  showNoteText,
}) => {
  const [showExpiry, setShowExpiry] = useState(false);
  return (
    <>
      <InputWithLabel label={selectAddressProofLabel} required={true}>
        <FormControl fullWidth>
          <Select
            name='addressProofType'
            value={addressProof.selectedApDocType}
            onChange={(e) => {
              setAddressProof({ ...addressProof, selectedApDocType: e.target.value });
              const selectedDocType = addressProofOptions.find((o) => o.value === e.target.value);
              setShowExpiry(selectedDocType?.showExpiryField ?? false);
              handleAddressProofChange(e);
            }}
          >
            {addressProofOptions.map((option, index) => (
              <MenuItem key={index} value={option.value ?? option}>
                <Translate value={option.displayName ?? 'ID_TYPE_LABEL_' + option} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {showNoteText && addressProof.selectedApDocType && (
          <FormHelperText>
            <Translate value='JS_NOTE' />
            :&nbsp;
            <Translate
              value={
                addressProofOptions.find((e) => e.value === addressProof.selectedApDocType)
                  ?.noteText
              }
            />
          </FormHelperText>
        )}
      </InputWithLabel>

      {showExpiry && (
        <DatePicker
          name='addressProofDOE'
          labelKey='Date of Expiry'
          value={addressProof.dateOfAddrProofExpiry}
          handleChange={(e) =>
            setAddressProof({
              ...addressProof,
              dateOfAddrProofExpiry: e.target.value,
            })
          }
          mandatory={true}
          minDate={new Date().toISOString().split('T')[0]}
        />
      )}
    </>
  );
};

const DocUploadSection = ({
  model,
  documents,
  updateDoc,
  handleAddressProofChange,
  showLoanDocuments,
  identificationDocumentTypes,
  getDynamicFieldLabel,
}) => {
  const [addressProof, setAddressProof] = useState({
    selectedApDocType: '',
    dateOfAddrProofExpiry: '',
  });
  const [dateOfAddrProofExpiryRequired, setDateOfAddrProofExpiryRequired] = useState(false);
  useEffect(() => {
    if (model.paymentType.id === 'INR_BANK_ACCOUNT') {
      const document = ADDRESS_PROOF_DOC_OPTIONS_INR_BANK.find(
        (doc) => doc.value === addressProof.selectedApDocType,
      );
      if (document) {
        setDateOfAddrProofExpiryRequired(document.showExpiryField);
      }
    }
  }, [addressProof]);

  if (!documents.length) {
    return <></>;
  }

  if (model.paymentType.id === 'VPBank_VND') {
    return (
      <div>
        <div className='mt-1 mb-2'>
          <DocUploadInfo paymentTypeId={model.paymentType.id} />
        </div>

        <AddressProofSelectionComponent
          paymentTypeID={model.paymentType.id}
          addressProof={addressProof}
          setAddressProof={setAddressProof}
          addressProofOptions={ADDRESS_PROOF_DOC_OPTIONS_VP_BANK}
          handleAddressProofChange={handleAddressProofChange}
          selectAddressProofLabel={'JS_UPLOAD_DOC_TYPE'}
          showNoteText={true}
        />

        {addressProof.selectedApDocType &&
          addressProof.dateOfAddrProofExpiry &&
          documents.map((doc, index) => (
            <DocUploader
              {...doc}
              updateDoc={(data) => updateDoc(doc.docSeq, data)}
              key={index}
              getDynamicFieldLabel={getDynamicFieldLabel}
            />
          ))}
      </div>
    );
  } else if (model.paymentType.id === 'INR_BANK_ACCOUNT') {
    return (
      <div>
        <DocUploadInfo paymentTypeId={model.paymentType.id} />
        <div className='mb-6'></div>
        {/* Section 1: PAN Card */}
        {documents.slice(2, 3).map((doc, index) => (
          <DocUploader
            {...doc}
            updateDoc={(data) => updateDoc(doc.docSeq, data)}
            key={index}
            getDynamicFieldLabel={getDynamicFieldLabel}
          />
        ))}

        {/* Section 2 : Proof of address */}
        <h3 className='font-bold'>Proof of address</h3>
        <ul className='list-disc ps-12 mb-6'>
          <li>Please note Aadhar card is not accepted as address proof</li>
          <li>All documents must be valid</li>
        </ul>
        <AddressProofSelectionComponent
          paymentTypeID={model.paymentType.id}
          addressProof={addressProof}
          setAddressProof={setAddressProof}
          addressProofOptions={ADDRESS_PROOF_DOC_OPTIONS_INR_BANK}
          handleAddressProofChange={handleAddressProofChange}
          selectAddressProofLabel={getDynamicFieldLabel('JS_UPLOAD_DOC_TYPE')}
          showNoteText={true}
        />
        {addressProof.selectedApDocType &&
          (!dateOfAddrProofExpiryRequired || addressProof.dateOfAddrProofExpiry) &&
          documents
            .slice(0, 2)
            .map((doc, index) => (
              <DocUploader
                {...doc}
                updateDoc={(data) => updateDoc(doc.docSeq, data)}
                key={index}
                getDynamicFieldLabel={getDynamicFieldLabel}
              />
            ))}

        {/* Section 3 : Proof of loan */}
        {showLoanDocuments && <h3 className='font-bold'>Proof of loan documents</h3>}
        {documents.slice(3, 5).map((doc, index) => (
          <DocUploader
            {...doc}
            updateDoc={(data) => updateDoc(doc.docSeq, data)}
            key={index}
            getDynamicFieldLabel={getDynamicFieldLabel}
          />
        ))}
      </div>
    );
  } else if (model.paymentType.id === 'ALIPAY_DIRECT_CHINA') {
    return (
      <div>
        <DocUploadInfo paymentTypeId={model.paymentType.id} />
        <div className='mb-6'></div>
        {/* Section : Upload tuition bill or invoice */}
        <p>
          Upload your tuition bill or invoice issued by your Institution. It must include the
          following information:
        </p>
        <ul className='list-disc ps-12 mb-6'>
          <li>Student name</li>
          <li>Institution name or logo</li>
          <li>Invoice amount</li>
          <li>Due date or date of academic year of study</li>
        </ul>
        {documents.slice(0, 1).map((doc, index) => (
          <DocUploader
            {...doc}
            updateDoc={(data) => updateDoc(doc.docSeq, data)}
            key={index}
            getDynamicFieldLabel={getDynamicFieldLabel}
          />
        ))}

        {/* Section 2 : Proof of identity */}
        <AddressProofSelectionComponent
          paymentTypeID={model.paymentType.id}
          addressProof={addressProof}
          setAddressProof={setAddressProof}
          addressProofOptions={identificationDocumentTypes}
          handleAddressProofChange={handleAddressProofChange}
          showDateOfExpiryField={false}
          selectAddressProofLabel={getDynamicFieldLabel('IDENTIFICATION_TYPE_LABEL')}
          showNoteText={false}
        />
        {addressProof.selectedApDocType &&
          documents
            .slice(1, 2)
            .map((doc, index) => (
              <DocUploader
                {...doc}
                updateDoc={(data) => updateDoc(doc.docSeq, data)}
                key={index}
                getDynamicFieldLabel={getDynamicFieldLabel}
              />
            ))}
      </div>
    );
  }

  return (
    <div>
      {documents.map((doc, index) => (
        <DocUploader
          {...doc}
          updateDoc={(data) => updateDoc(doc.docSeq, data)}
          key={index}
          getDynamicFieldLabel={getDynamicFieldLabel}
        />
      ))}
    </div>
  );
};

export default DocUploadSection;
