import { Alert } from '@mui/material';
import Button from '@mui/material/Button';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { captureQuote, getMoreOptions, initiatePayment } from '../../apis';
import DebtorPortalTextDisplay from '../../components/DebtorPortalTextDisplay';
import { RawHtmlContent } from '../../components/Format';
import { useDynamicColumns } from '../../hooks/responsiveHook';
import { Translate } from '../../i18n/translate';
import { showModalWithFunctions } from '../../store/slices/modal/actions';
import {
  ROUTE_MAPPING,
  navigate,
  navigateBack,
  showPaymentSummaryAction,
} from '../../store/slices/router/routerSlice';
import {
  selectData,
  selectLocale,
  selectPayerCountry,
  setData,
  setSelectedQuote,
} from '../../store/slices/session/sessionSlice';
import { getCurrency } from '../../utils/quoteService';
import './choosePayment.scss';
import PaymentMethodCard from './methodCard';

const ChoosePaymentMethod = () => {
  const [quotes, setQuotes] = useState([]);
  const [moreOptionsAvailable, setMoreOptionsAvailable] = useState(false);
  const [noOptions, setNoOptions] = useState(false);
  const locale = useSelector(selectLocale);
  const payerCountry = useSelector(selectPayerCountry);
  const session = useSelector(selectData);
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const columns = useDynamicColumns(1, containerRef, 700);
  const intl = useIntl();

  const VPBankPopUp = () => <RawHtmlContent htmlContent={'JS_VPBank_VND_MODAL_BODY'} />;

  useEffect(() => {
    dispatch(showPaymentSummaryAction());
    fetchData();
  }, []);

  const _getMoreOptions = async () => {
    try {
      const response = await getMoreOptions();
      const quote = response.data;
      handleQuoteSuccess(quote);
    } catch (error) {
      console.error(`Get more options API failed ${error.message}`);
    }
  };

  const handleQuoteSuccess = (quote) => {
    setMoreOptionsAvailable(false);
    const quotesReduced = _(quote.currencyQuoteList).reduce((arr, cQuote) => {
      var filteredList = cQuote.initiateQuoteResponseList;
      if (payerCountry === 'USA' && locale !== 'en_GB') {
        filteredList = cQuote.initiateQuoteResponseList.filter((item) => {
          return item.paymentType.defaultPspId !== 'WorldPay';
        });
        if (filteredList.length === 0 && quote.moreOptionsAvailable) {
          _getMoreOptions();
        }
      }
      return _.concat(arr, filteredList);
    }, []);
    if (quote.moreOptionsAvailable) {
      setMoreOptionsAvailable(true);
    }
    if (quotesReduced.length == 0) {
      setNoOptions(true);
    }
    setQuotes(quotesReduced);
  };

  const fetchData = async () => {
    try {
      const response = await initiatePayment();
      const quote = response.data;
      handleQuoteSuccess(quote);
    } catch (error) {
      console.error(`Payment Initiate API failed ${error.message}`);
    }
  };

  const selectQuote = (quote) => {
    confirmIfSameCurrencyAsUniversity(quote);
  };

  const translate = (key) => {
    return intl.formatMessage({ id: key });
  };

  const confirmIfSameCurrencyAsUniversity = (quote) => {
    if (session?.sellerInfo?.country === session?.selectedServices?.country) {
      captureQuoteFn(quote);
      return;
    }

    if (getCurrency(quote) === quote.homeCurrency) {
      dispatch(
        showModalWithFunctions({
          title: translate('JS_CURRENCY_MODAL_TITLE'),
          body: translate('JS_CURRENCY_MODAL_BODY'),
          yesLabel: translate('JS_CURRENCY_MODAL_CONTINUE_BTN'),
          noLabel: translate('JS_CURRENCY_MODAL_QUOTE_BTN'),
          yesAction: () => captureQuoteFn(quote),
          noAction: () => {},
          icon: null,
        }),
      );
    } else if (quote.paymentType.id === 'VPBank_VND') {
      dispatch(
        showModalWithFunctions({
          title: translate('JS_VPBank_VND_MODAL_TITLE'),
          body: <VPBankPopUp />,
          yesLabel: translate('JS_VPBank_VND_MODAL_CONTINUE_BTN'),
          noLabel: translate('JS_VPBank_VND_MODAL_QUOTE_BTN'),
          yesAction: () => captureQuoteFn(quote),
          noAction: () => {},
          icon: null,
        }),
      );
    } else if (quote.paymentType.id === 'GEOSWIFTUNIONPAY') {
      dispatch(
        showModalWithFunctions({
          title: translate('JS_UNIPAY_MODAL_TITLE'),
          body: translate('JS_UNIPAY_MODAL_BODY'),
          yesLabel: translate('JS_UNIPAY_MODAL_CONTINUE_BTN'),
          noLabel: translate('JS_UNIPAY_MODAL_QUOTE_BTN'),
          yesAction: () => captureQuoteFn(quote),
          noAction: () => {},
          icon: null,
        }),
      );
    } else if (quote.paymentType.id === 'ALIPAY_DIRECT_CHINA') {
      dispatch(
        showModalWithFunctions({
          title: '',
          body: translate('JS_ALIPAY_USER_MESSAGE'),
          yesLabel: translate('JS_ALIPAY_CONFIRM'),
          noLabel: translate('JS_CHANGE_PAYMENT_COUNTRY'),
          yesAction: () => captureQuoteFn(quote),
          noAction: () => dispatch(navigateBack()),
          icon: null,
        }),
      );
    } else {
      captureQuoteFn(quote);
    }
  };

  const captureQuoteFn = async (quote) => {
    dispatch(setSelectedQuote(quote));
    delete quote['selected'];

    const response = await captureQuote(quote);
    if (response.success) {
      dispatch(setData(response.data));
      if (response.data.sellerInfo.consolBuyerRemitterPageEnabled) {
        dispatch(navigate({ route: ROUTE_MAPPING.COSOLIDATED }));
        return;
      }
      dispatch(navigate({ route: ROUTE_MAPPING.BUYER_FROM }));
    }
  };

  return (
    <>
      <div className='container max-w-[750px]' ref={containerRef}>
        {noOptions && (
          <div className='mt-2'>
            <Alert severity='error' sx={{ alignItems: 'center', width: '100%' }}>
              <Translate value='JS_quote_not_available' />
            </Alert>
          </div>
        )}
        {!noOptions && (
          <h3 className={`text-16-bold my-5 ${columns === 2 ? 'text-center' : ''}`}>
            <Translate value='JS_STEP1_TITLE' />
          </h3>
        )}

        <DebtorPortalTextDisplay type={'CAWTP'} />

        {quotes.map((quote, index) => (
          <PaymentMethodCard
            key={index}
            quote={quote}
            onSelect={() => selectQuote(quote)}
            flexDir={columns === 2 ? 'row' : 'col'}
          />
        ))}

        {moreOptionsAvailable && (
          <div className='mt-1 text-center'>
            <Button
              variant='text'
              sx={{
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'transparent',
                },
                '&.MuiButtonBase-root.Mui-disabled': {
                  bgcolor: 'transparent',
                  opacity: 0.5,
                },
              }}
              onClick={_getMoreOptions}
              className='text-accent p-0 mr-5'
            >
              <Translate value='SHOW_MORE_OPTIONS' />
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
export default ChoosePaymentMethod;
