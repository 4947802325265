import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { ClickAwayListener, Tooltip } from '@mui/material';
import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectData, selectSsoRequest } from '../../store/slices/session/sessionSlice';
import { getSsoInfo } from '../../utils/helper';
import { RawHtmlContent } from '../Format';

const CopyButton = () => {
  const [open, setOpen] = useState(false);
  const intl = useIntl();

  const handleTooltipOpen = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 5000);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={intl.formatMessage({ id: 'JS_COPIED_SUCCESS' })}
        placement='top'
        data-html2canvas-ignore='true'
      >
        <ContentCopyOutlinedIcon
          className='text-14 cursor-pointer text-accent ml-1'
          onClick={handleTooltipOpen}
        />
      </Tooltip>
    </ClickAwayListener>
  );
};

const ClientRefDisplay = () => {
  const ssoRequest = useSelector(selectSsoRequest);
  const { clientRef } = getSsoInfo(ssoRequest);
  const session = useSelector(selectData);
  if (!clientRef) {
    return <></>;
  }

  return (
    <>
      <CopyToClipboard text={clientRef}>
        <div className='text-center'>
          <RawHtmlContent
            htmlContent='CLIENTREF_MSG'
            translateValues={{ clientRef: clientRef, sellerName: session.sellerInfo.name }}
            className='inline'
          />
          <CopyButton refNumber={clientRef} />
        </div>
      </CopyToClipboard>
    </>
  );
};

export default ClientRefDisplay;
