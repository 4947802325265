import _ from 'lodash';
import { useEffect } from 'react';

export const getSsoInfo = (ssoRequest) => {
  const parser = new DOMParser();
  if (ssoRequest.startsWith('<?xml')) {
    const xmlDoc = parser.parseFromString(ssoRequest, 'text/xml');
    const merchantId = xmlDoc.querySelector('merchantId')?.textContent;
    const merchantSecret = xmlDoc.querySelector('merchantSecret')?.textContent;
    const sellerId = xmlDoc.querySelector('clientId')?.textContent || 0; //Service provider value is 0, clientId is not passed
    const returnUrl = xmlDoc.querySelector('returnUrl')?.textContent;
    const reqLocale = xmlDoc.querySelector('locale')?.textContent || 'en_GB';
    const buyerId = xmlDoc.querySelector('buyer id')?.textContent || '';
    const clientRef = xmlDoc.querySelector('clientReference')?.textContent || '';
    const gpfsData = {
      initiatedFromGPFS: false,
      consolBuyerRemitterPageEnabled: false,
      showReceiptForRedirectPayments: false,
    };
    return {
      merchantId,
      merchantSecret,
      sellerId,
      reqLocale,
      buyerId,
      returnUrl,
      clientRef,
      gpfsData,
    };
  } else {
    // json case
    const jsonReq = JSON.parse(ssoRequest);
    const merchantId = jsonReq.paymentInformation.merchantId;
    const merchantSecret = jsonReq.paymentInformation.merchantSecret;
    const sellerId = jsonReq.paymentInformation.clientId || 0; //Service provider value is 0, clientId is not passed
    const returnUrl = jsonReq.paymentInformation.returnUrl;
    const reqLocale = jsonReq.paymentInformation.locale || 'en_GB';
    const buyerId = jsonReq.paymentInformation.buyer?.id || '';
    const clientRef = jsonReq.paymentInformation.clientReference || '';
    const gpfsData = jsonReq.paymentInformation.gpfsData || {
      initiatedFromGPFS: false,
      consolBuyerRemitterPageEnabled: false,
      showReceiptForRedirectPayments: false,
    };

    return {
      merchantId,
      merchantSecret,
      sellerId,
      reqLocale,
      buyerId,
      returnUrl,
      clientRef,
      gpfsData,
    };
  }
};

export const hasPayloadIncludeServiceIds = (ssoRequest) => {
  const parser = new DOMParser();
  try {
    if (ssoRequest.trim().startsWith('<?xml')) {
      const xmlDoc = parser.parseFromString(ssoRequest, 'text/xml');
      const serviceNodes = xmlDoc.querySelectorAll('service');
      return serviceNodes.length !== 0;
    } else {
      const jsonReq = JSON.parse(ssoRequest);
      const { paymentInformation } = jsonReq;
      return paymentInformation.service && paymentInformation.service.length !== 0;
    }
  } catch (error) {
    console.error('Invalid SSO request format:', error);
    return false; // Return false if parsing fails
  }
};

export const getConfig = (refDataCache) => {
  var oldFeeDisplayLogicEnabled;
  if (refDataCache.configList && refDataCache.configList.length > 0)
    var config = (oldFeeDisplayLogicEnabled = refDataCache.configList.find((config) => {
      return (
        config.configKey && config.configKey === 'enable.mcp.credit.card.old.fee.display.logic'
      );
    }));
  if (config) {
    oldFeeDisplayLogicEnabled = config.configValue;
  }
  if (oldFeeDisplayLogicEnabled === undefined) {
    oldFeeDisplayLogicEnabled = 'false';
  }
  return oldFeeDisplayLogicEnabled;
};

export const getConfigValue = (keyName, configList) => {
  return configList.find((e) => e.configKey === keyName)?.configValue;
};

export const useScript = (src, onload, insertOnCondtion = true) => {
  useEffect(() => {
    if (insertOnCondtion) {
      let script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.onload = onload;
      script.onerror = () => {
        console.error(`Failed to load script ${src}`);
      };

      document.body.appendChild(script);

      // Cleanup script
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [insertOnCondtion, src]);
};

export const geValidRegex = (refDataCache) => {
  if (refDataCache.configList && refDataCache.configList.length > 0) {
    const result = refDataCache.configList.find(
      (item) => item.configKey === 'gpfs.special.characters.allowed',
    );
    return result
      ? result.configValue
      : "^[àèìòÀÈÌÒáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæa-zA-Z0-9,\\\\\\\\+\\\\\\\\(\\\\\\\\)\\\\\\\\?\\\\\\\\./:'\\\\-\\\\\\s]+$";
  }
  return "^[àèìòÀÈÌÒáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæa-zA-Z0-9,\\\\\\\\+\\\\\\\\(\\\\\\\\)\\\\\\\\?\\\\\\\\./:'\\\\-\\\\\\s]+$";
};

export const parseBoolean = (key, accessResponse) =>
  accessResponse[key]?.length === 1 && accessResponse[key][0] === 'true';

export const getTotalAmount = (serviceItems) =>
  serviceItems.reduce(
    (total, item) =>
      total + (item.mandatory || item.included ? parseFloat(item.amountOwing || 0) : 0),
    0,
  );

export const getReturnUrlConfig = function (state) {
  var logoUrl;
  var returnUrl;
  if (!_.isEmpty(state.returnUrl)) {
    logoUrl = state.returnUrl;
  } else if (
    !_.isNull(state.sellerInfo) &&
    !_.isNull(state.sellerInfo.debtorPortalBrandingView) &&
    !_.isNil(state.sellerInfo.debtorPortalBrandingView.logoUrl)
  ) {
    logoUrl = state.sellerInfo.debtorPortalBrandingView.logoUrl;
  }
  if (!_.isEmpty(logoUrl)) {
    returnUrl = _.startsWith(logoUrl, 'http') ? logoUrl : '//' + logoUrl;
  }

  var enableReturn =
    !_.isNull(state.sellerInfo) && !_.isNull(state.sellerInfo.debtorPortalBrandingView)
      ? state.sellerInfo.debtorPortalBrandingView.enableReturnURL
      : false;

  return {
    returnUrl: returnUrl,
    enableReturn: enableReturn,
  };
};
