import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { saveBuyerDetails } from '../../apis';
import DebtorPortalTextDisplay from '../../components/DebtorPortalTextDisplay';
import { Translate } from '../../i18n/translate';
import { ROUTE_MAPPING, navigate } from '../../store/slices/router/routerSlice';
import {
  selectConsolBuyerRemitterPageEnabled,
  selectData,
  setData,
} from '../../store/slices/session/sessionSlice';
import ConslidatedContext from '../ConsolidatedPage/consolidatedContext';
import StudentForm from './studentForm';

const StudentDetail = () => {
  const session = useSelector(selectData);
  const dispatch = useDispatch();
  const intl = useIntl();
  const [, setModel] = useState({
    selectedQuote: null,
  });
  const [buyerData, setBuyerData] = useState({});
  const [missingAllowedFields, setMissingAllowedFields] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [documentsVerified, setDocumentsVerified] = useState(false);
  const [onlineCourseEnrolment, setOnlineCourseEnrollment] = useState(false);
  const [customFields, setCustomFields] = useState([]);
  const consolBuyerRemitterPageEnabled = useSelector(selectConsolBuyerRemitterPageEnabled);
  const consolidatedContext = useContext(ConslidatedContext);

  useEffect(() => {
    if (!session) {
      return;
    }

    setModel({
      selectedQuote: session.selectedQuote,
    });

    const buyerDataObj = {};
    const customFieldListCopy = [...session.customFieldList];
    const addressFoundIndex = _.findIndex(customFieldListCopy, (item) =>
      _.includes(item, 'address'),
    );
    const address2FoundIndex = _.findIndex(customFieldListCopy, (item) =>
      _.includes(item, 'address2'),
    );

    if (address2FoundIndex == -1 && addressFoundIndex != -1) {
      customFieldListCopy.splice(addressFoundIndex + 1, 0, {
        name: 'address2',
        type: 'TEXT',
        mandatory: false,
        labelKey: 'address2_LABEL',
        helpKey: 'address2_HELP',
        regex:
          "^[ àèìòÀÈÌÒáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæa-zA-Z0-9,.:'\\+\\(\\)\\?'\\-/]*$",
        validationId: 0,
        scriptBasedValidation: false,
        errorKey: 'address2_ERROR',
      });
    }

    customFieldListCopy.forEach((customField) => {
      buyerDataObj[customField.name] = {
        name: customField.name,
        validationId: customField.validationId,
        scriptBasedValidation: customField.scriptBasedValidation,
      };
    });

    setBuyerData(buyerDataObj);
    setCustomFields(customFieldListCopy);

    // Update buyerData based on buyerDetails
    if (!_.isNil(session.buyerDetails)) {
      const updatedBuyerData = { ...buyerDataObj };

      _.forEach(session.buyerDetails, (value, key) => {
        if (_.isObject(updatedBuyerData[key])) {
          if (key === 'address' && value && value.lastIndexOf(', ') != -1) {
            updatedBuyerData[key].value = value.substring(0, value.lastIndexOf(','));
            if (updatedBuyerData['address2']) {
              updatedBuyerData['address2'].value = value.substring(
                value.lastIndexOf(', ') + 2,
                value.length,
              );
            }
          } else {
            updatedBuyerData[key].value = value;
          }
        }
      });

      setBuyerData(updatedBuyerData);
    }

    // Update buyerData based on buyerDetails.customFields
    if (!_.isNil(session.buyerDetails) && !_.isNil(session.buyerDetails.customFields)) {
      const updatedBuyerData = { ...buyerDataObj };

      _.forEach(session.buyerDetails.customFields, (value, key) => {
        if (_.isObject(updatedBuyerData[key])) {
          updatedBuyerData[key].value = value;
        }
      });

      setBuyerData(updatedBuyerData);
    }

    // Update missingAllowedFields based on session data
    setMissingAllowedFields(
      session.sso &&
        (!buyerDataObj['firstName'].value ||
          !buyerDataObj['lastName'].value ||
          !buyerDataObj['email'].value),
    );

    if (
      !session.kycDocList ||
      session.kycDocList.length === 0 ||
      session.selectedQuote.paymentType.id !== 'VPBank_VND'
    ) {
      setDocumentsVerified(true);
    }

    if (session.selectedQuote.paymentType.id === 'VPBank_VND') {
      generateDocList(session.kycDocList, [1, 2, 3, 8]);
    }
  }, [session]);

  useEffect(() => {
    if (session.selectedQuote.paymentType.id === 'VPBank_VND') {
      generateDocList(session.kycDocList, [1, 2, 3, 8]);
    }
  }, [onlineCourseEnrolment]);

  const generateDocList = (kycDocList, docSeqArray) => {
    // docSeqArray  is used to identify which docs must be picked from kycDocList to show in student detail page. Rest are shown in payer detail page
    let docs = [];

    kycDocList.forEach((doc) => {
      if (doc.isEnabled && doc.reactConditionalExpression != null) {
        let conditionalExp = docSeqArray.includes(doc.docSeq)
          ? eval(doc.reactConditionalExpression)
          : false;
        if (conditionalExp) {
          let exisitingDoc = documents.find((e) => e.docSeq === doc.docSeq);

          if (exisitingDoc) {
            docs.push(exisitingDoc);
          } else
            docs.push({
              required: doc.isMandatory,
              labelName: doc.description,
              docSeq: doc.docSeq,
              isVerified: false,
              isError: false,
              paymentTypeId: doc.paymentTypeId,
            });
        }
      }
    });
    verifyDocs(docs);
    setDocuments(docs);
  };

  const getDynamicFieldLabel = (defaultLabel) => {
    let label = session.selectedQuote.paymentType.id + '_' + defaultLabel;
    var translatedLabel = getTranslatedValue(label);
    if (translatedLabel == null || translatedLabel === label) {
      return getTranslatedValue(defaultLabel);
    }
    return translatedLabel;
  };

  const getTranslatedValue = (label) => {
    return intl.formatMessage({ id: label });
  };

  // const isWebIntegrationFieldsIgnorable = (session) => {
  //   return (
  //     session.sso &&
  //     session.sellerInfo.webIntegrationEnabled &&
  //     session.sellerInfo.isWebIntegrationFieldsIgnorable
  //   );
  // };

  // const isWebIntegrationFieldsEditable = (session) => {
  //   return (
  //     session.sso &&
  //     session.sellerInfo.webIntegrationEnabled &&
  //     session.sellerInfo.isWebIntegrationFieldsEditable
  //   );
  // };

  // const forceEditable = () => {
  //   return (
  //     isWebIntegrationFieldsEditable() || isWebIntegrationFieldsIgnorable() || missingAllowedFields
  //   );
  // };

  const validateBuyerForm = async (formData) => {
    let submitData = { ...formData };
    if (submitData.address2) {
      const address2 = submitData.address2;
      submitData.address += ', ' + address2;
      submitData.address =
        submitData.address.length > 255 ? submitData.address.substring(0, 255) : submitData.address;
    }
    submitData = _.omit(submitData, 'address2');
    const updateData = { ...submitData };
    updateData.customFields = {};

    Object.keys(submitData).forEach((key) => {
      if (key.startsWith('custom')) {
        updateData.customFields[key] = updateData[key];
        delete updateData[key];
      }
    });

    if (!updateData.businessId) {
      return;
    }
    const response = await saveBuyerDetails(updateData);
    if (response.success) {
      if (consolBuyerRemitterPageEnabled) {
        if (consolidatedContext) {
          const { setIsBuyerFormSubmitted } = consolidatedContext;
          setIsBuyerFormSubmitted(true);
        }
      } else {
        dispatch(setData(response.data));
        dispatch(navigate({ route: ROUTE_MAPPING.PAYER_FORM }));
      }
    }
  };

  // // TODO: Call this function from student form on clicking submit
  // const validateBuyerForm2 = (buyerDetailsForm, initCheck) => {
  //   if (!session.sso || session.buyerCanEdit || forceEditable()) {
  //     //TODO: handle the below condition for react
  //     if (!buyerDetailsForm.$invalid && buyerDetailsForm.businessId.$viewValue != null) {
  //       validateBuyer(buyerData).then((result) => {
  //         if (result) {
  //           if (initCheck) {
  //             return true;
  //           }
  //         } else {
  //           let buyerDetails = separateCustomAndStandardFields();
  //           save(buyerDetails);
  //         }
  //       });
  //     } else if (initCheck) {
  //       return false;
  //     }
  //   } else {
  //     //TODO: handle the below condition for react
  //     if (buyerDetailsForm.email.$valid && buyerDetailsForm.businessId.$valid) {
  //       saveEmail(''); //TODO: get the buyer email from the student form
  //       // condition only works for initial check of pre-populated fields
  //       if (initCheck) {
  //         return true;
  //       }
  //     } else if (initCheck) {
  //       return false;
  //     }
  //   }
  // };

  const isPaymentFieldVisible = (fieldId) => {
    if (!session.paymentTypeFields || !session.paymentTypeFields.fieldList) return false;
    let fieldDetails = session.paymentTypeFields.fieldList.find((e) => e.fieldId === fieldId);
    return fieldDetails?.visible;
  };

  // const save = async () => {
  //   let buyerDetails = separateCustomAndStandardFields();

  //   const response = await saveBuyerDetails(buyerDetails);
  //   if (response.success) {
  //     // go to payer detail page
  //     dispatch(navigate({ route: ROUTE_MAPPING.PAYER_FORM }));
  //   }
  // };

  // const saveEmail = async (email) => {
  //   const response = await saveBuyerEmail(email);
  //   if (response.success) {
  //     // go to payer detail page
  //     dispatch(setData(response.data));
  //     dispatch(navigate({ route: ROUTE_MAPPING.PAYER_FORM }));
  //   }
  // };

  const updateDoc = (index, data) => {
    let updatedDocs = documents.map((e, i) => (i === index ? { ...e, ...data } : e));
    setDocuments(updatedDocs);

    verifyDocs(updatedDocs);
  };

  const verifyDocs = (docs) => {
    let allVerified = docs
      .filter((doc) => doc.required) // Filter only required documents
      .every((doc) => doc.isVerified);
    setDocumentsVerified(allVerified);
  };

  return (
    <div
      className={`container max-w-[750px] ${
        session.sellerInfo.consolBuyerRemitterPageEnabled ? 'pb-5' : 'py-2'
      }`}
    >
      {!session.sellerInfo.consolBuyerRemitterPageEnabled && (
        <h4 className='text-16-bold mb-3'>
          <Translate value='JS_StudentDetails' />
        </h4>
      )}

      <DebtorPortalTextDisplay type={'BUYER'} />

      <div className='mb-3 mt-2'>
        <Translate value='JS_StudentDetailsIdentify' className={'text-gray1 text-14-400'} />
      </div>

      {customFields?.length > 0 && (
        <StudentForm
          documents={documents}
          documentsVerified={documentsVerified}
          updateDoc={(index, data) => updateDoc(index, data)}
          customFields={customFields}
          validate={(formData) => validateBuyerForm(formData)}
          buyerData={buyerData}
          missingAllowedFields={missingAllowedFields}
          isPaymentFieldVisible={(fieldId) => isPaymentFieldVisible(fieldId)}
          onlineCourseEnrolment={onlineCourseEnrolment}
          setOnlineCourseEnrollment={(e) => setOnlineCourseEnrollment(e.target.checked)}
          getDynamicFieldLabel={getDynamicFieldLabel}
        />
      )}
    </div>
  );
};

export default StudentDetail;
