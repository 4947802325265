import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectData, selectInitiatedFromGPFS } from '../../store/slices/session/sessionSlice';

const BrandDisplay = () => {
  const [title, setTitle] = useState();
  const [clientFontSize, setClientFontSize] = useState();
  const [fontSize, setFontSize] = useState(clientFontSize);
  const textRef = useRef(null);
  const containerRef = useRef(null);
  const session = useSelector(selectData);
  const [brandLogoUrl, setBrandLogoUrl] = useState('');
  const initiatedFromGpfs = useSelector(selectInitiatedFromGPFS);

  useEffect(() => {
    if (session?.sellerInfo?.debtorPortalBrandingView) {
      setFontSize(session.sellerInfo.debtorPortalBrandingView.clientNameFontSize || '24');
      setTitle(
        session.sellerInfo?.debtorPortalBrandingView?.clientDisplayNameOverride ||
          session.sellerInfo.name,
      );
      setClientFontSize(session.sellerInfo.debtorPortalBrandingView?.clientNameFontSize || '24');
      getBrandLogoUrl();
    }
  }, [session]);

  const resizeToFit = () => {
    if (
      textRef.current &&
      containerRef.current &&
      textRef.current.clientWidth >= containerRef.current.clientWidth
    ) {
      setFontSize(fontSize - 1);
    }
  };

  useEffect(() => {
    if (
      textRef.current &&
      containerRef.current &&
      textRef.current.clientWidth >= containerRef.current.clientWidth
    ) {
      resizeToFit();
    }
  }, [fontSize, title]);

  useEffect(() => {
    const handleResize = () => {
      setFontSize(fontSize - 1);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [clientFontSize]);

  const getBrandLogoUrl = () => {
    const logoUrl = session.sellerInfo.debtorPortalBrandingView.logoUrl;
    const fallbackUrl = `${process.env.REACT_APP_BUYER_URL}/geo-buyer/_assets/logo?sellerId=${session.sellerInfo.sellerId}`;

    if (!logoUrl) {
      loadImage(fallbackUrl, setBrandLogoUrl, () => setBrandLogoUrl(null));
      return;
    }

    loadImage(logoUrl, setBrandLogoUrl, () => {
      loadImage(fallbackUrl, setBrandLogoUrl, () => setBrandLogoUrl(null));
    });
  };

  const loadImage = (url, onSuccess, onFailure) => {
    const img = new Image();
    img.src = url;
    img.onload = () => onSuccess(url);
    img.onerror = onFailure;
  };

  if (!initiatedFromGpfs || !session?.sellerInfo) {
    return <></>;
  }

  return (
    <div
      className='flex flex-col sm:flex-row items-center px-5 min-h-[130px]  border-b-4 border-b-lightGray10'
      style={{
        backgroundColor:
          session.sellerInfo?.debtorPortalBrandingView?.clientBannerBackgroundColor || '#FFFFFF',
      }}
    >
      {brandLogoUrl && (
        <div className='max-w-[30%] lg:max-w-[calc((100%-750px)/2)] mr-5 py-1 lg:py-2'>
          <img src={brandLogoUrl} />
        </div>
      )}

      <div className={`w-full sm:w-[70%]  ${!brandLogoUrl && 'm-auto'}`} ref={containerRef}>
        <p
          ref={textRef}
          className={`whitespace-nowrap m-auto w-fit font-bold ${
            !brandLogoUrl ? 'sm:m-auto' : 'sm:m-[unset]'
          }`}
          style={{
            fontSize: `${fontSize}px`,
            color: session.sellerInfo?.debtorPortalBrandingView?.clientNameFontColor || '#000000',
          }}
        >
          {title}
        </p>
      </div>
    </div>
  );
};

export default BrandDisplay;
