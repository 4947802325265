import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Alert, Box, Button, Collapse } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getServiceDetails, getToken } from '../../apis';
import { Translate } from '../../i18n/translate';
import { initRefdata } from '../../store/slices/refdata/action';
import {
  ROUTE_MAPPING,
  activateRoutes,
  hidePaymentSummaryAction,
  navigate,
  setRoutes,
  setShowStepper,
} from '../../store/slices/router/routerSlice';
import {
  selectError,
  selectSsoRequest,
  setCatalog,
  setData,
  setError,
  setHideReceipt,
  setHideServiceCatalog,
  setHomeAmount,
  setHomeCurrency,
  setIsGPXClient,
  setShowReference,
  setToken,
} from '../../store/slices/session/sessionSlice';
import { getSsoInfo, getTotalAmount, parseBoolean } from '../../utils/helper';

export const GenericError = () => {
  const dispatch = useDispatch();
  const error = useSelector(selectError);
  const ssoRequest = useSelector(selectSsoRequest);
  const route = useSelector((state) => state.router.routeName);

  const [open, setOpen] = useState(true);
  const [hideButton, setHideButton] = useState(false);
  const [showLandingPage, setShowLandingPage] = useState(false);

  useEffect(() => {
    dispatch(hidePaymentSummaryAction());
  }, [dispatch]);

  const fetchToken = async () => {
    try {
      const { merchantId, merchantSecret, sellerId, buyerId, gpfsData } = getSsoInfo(ssoRequest);
      const response = await getToken({ sellerId, merchantId, merchantSecret, buyerId });

      if (!response.success) return;

      const { Authorization, ...accessResponse } = response.data;
      const token = Authorization[0];

      dispatch(setToken(token));

      const hideServiceCatalog = parseBoolean('hideServiceCatalogue', accessResponse);
      const hideReceipt = parseBoolean('hideReceiptPage', accessResponse);
      const enableLanding = parseBoolean('displayLandingPage', accessResponse);
      const showRef = parseBoolean('showReference', accessResponse);

      setShowLandingPage(enableLanding);

      let consolBuyerRemitterPageEnabled = false;
      if (!enableLanding) {
        const serviceResponse = (await getServiceDetails(ssoRequest)).data.data;
        consolBuyerRemitterPageEnabled = serviceResponse.sellerInfo.consolBuyerRemitterPageEnabled;
        if (hideServiceCatalog) {
          dispatch(setHideServiceCatalog(true));
          const serviceItems = (serviceResponse.serviceItemViewList?.serviceItemViews || []).map(
            (item) => ({
              ...item,
              included: true,
            }),
          );
          const isGpx = serviceResponse.sellerInfo.sellerIndustry !== 'EDUCATION';
          dispatch(setIsGPXClient(isGpx));
          dispatch(setHomeAmount(getTotalAmount(serviceItems)));
          dispatch(setHomeCurrency(serviceResponse.sellerInfo?.homeCurrency));
        }
        dispatch(setData(serviceResponse));
      }

      dispatch(
        setRoutes({
          hideServiceCatalog,
          consolBuyerRemitterPageEnabled:
            consolBuyerRemitterPageEnabled || gpfsData.consolBuyerRemitterPageEnabled,
          hideReceiptPage: hideReceipt,
          enableLanding: enableLanding && !gpfsData.initiatedFromGPFS,
        }),
      );
      dispatch(setHideReceipt(hideReceipt));
      dispatch(initRefdata());
      dispatch(setShowStepper(true));
      dispatch(setShowReference(showRef));
      dispatch(setCatalog(null));
      dispatch(setError(null));
      dispatch(activateRoutes(true));

      if (hideServiceCatalog) dispatch(navigate({ route: ROUTE_MAPPING.PAYMENT_METHOD }));
      if (enableLanding && !gpfsData.initiatedFromGPFS)
        dispatch(navigate({ route: ROUTE_MAPPING.LANDING }));
    } catch (error) {
      setHideButton(false);
      console.error(`Token API ${error.message}`);
    }
  };

  return (
    <div className='pt-3 pb-3'>
      <Box sx={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
        <Collapse in={open}>
          <Alert
            sx={{ backgroundColor: '#FD7557', display: 'flex', alignItems: 'center' }}
            icon={<ErrorOutlineOutlinedIcon className='text-black ml-1' />}
            severity='error'
            onClose={() => setOpen(false)}
          >
            <span className='font-bold text-black'>Server Error</span>
            {error?.error && (
              <span className='font-normal text-black text-16'>
                — {error.error.message || 'An error has occurred or your session has timed out.'}{' '}
                {error.error.code && `(Error ID: ${error.error.code})`}
              </span>
            )}
          </Alert>
        </Collapse>
      </Box>
      <div className='flex justify-center'>
        {!hideButton && (
          <Button
            className='text-14 font-medium mt-2 rounded-full pt-[14px] pb-[14px] pl-[24px] pr-[24px]'
            variant='contained'
            onClick={() => {
              dispatch(setError(null));
              setOpen(false);
              setHideButton(true);
              if (!showLandingPage && route !== ROUTE_MAPPING.SERVICE_CATALOG) {
                dispatch(navigate({ route: ROUTE_MAPPING.SERVICE_CATALOG }));
              }
              fetchToken();
            }}
          >
            <Translate value='START_NEW_QUOTE' />
          </Button>
        )}
      </div>
    </div>
  );
};
