import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CountrySelect } from '../../components/CountryDropDown';
import { RawHtmlContent } from '../../components/Format';
import InputWithLabel from '../../components/InputWithLabel';
import SmartAddress from '../../components/SmartAddress';
import { Translate } from '../../i18n/translate';
import {
  selectAllCountries,
  selectSmartyEnabledCountries,
} from '../../store/slices/refdata/refdataSlice';
import { selectConsolBuyerRemitterPageEnabled } from '../../store/slices/session/sessionSlice';
import PayerFormContext from './payerFormContext';

const PayerAddressSection = ({
  handleInputChange,
  handleBlur,
  setCountry,
  setFormData,
  validateField,
  setErrors,
}) => {
  const {
    model,
    isDfTxn,
    isFieldVisible,
    isApolloCreditCard,
    iftiEnabled,
    isFieldMandatory,
    stateProvinces,
    getLabelforPayer,
    getLabelForCaState,
    isUsAch,
    isUsOnlineBanking,
    isGPXCan,
    isGPXCaml,
    //directly from payer form
    formData,
    errors,
    validations,
  } = useContext(PayerFormContext);
  const [smartyEnabled, setSmartyEnabled] = useState(false);
  const allCountries = useSelector(selectAllCountries);
  const consolBuyerRemitterPageEnabled = useSelector(selectConsolBuyerRemitterPageEnabled);
  const smartyEnabledCountries = useSelector(selectSmartyEnabledCountries);

  useEffect(() => {
    const _isSmartyEnabled =
      model.session.selectedQuote.paymentType.smartyEnabled &&
      model.session.sellerInfo.isSmartyEnabledClient &&
      smartyEnabledCountries.includes(formData.payerCountry) &&
      (consolBuyerRemitterPageEnabled || model.payer.who === 'SOMEONE_ELSE');
    setSmartyEnabled(_isSmartyEnabled);
  }, [model, formData.payerCountry]);

  const fn2bCalledAfterCountrySet = (v) => {
    if (v !== formData['payerCountry']) {
      const payerData = {
        payerAddress: '',
        payerAddress2: '',
        payerCity: '',
        payerZip: '',
        payerState: '',
        payerCountry: v,
      };
      setFormData((prevState) => ({
        ...prevState,
        ...payerData,
      }));

      setErrors({
        ...errors,
        payerAddress: '',
        payerAddress2: '',
        payerCity: '',
        payerState: '',
        payerZip: '',
      });
    } else {
      setCountry('payerCountry', v);
    }
  };

  return (
    <div>
      <h4 className='text-16-bold mb-3'>
        <Translate value='JS_PAYER_BILLING_ADDRESS_TITLE' />
      </h4>
      {model.paymentType.id == 'INR_BANK_ACCOUNT' && (
        <p>
          <div className='p-5 mt-1 bg-warning mb-1'>
            <Translate className={'font-bold'} value='JS_Note_Emphasis' />
            <RawHtmlContent
              htmlContent={'INR_BANK_ACCOUNT_JS_PAYER_BILLING_ADDRESS_EXPLANATION'}
              className='inline-block'
            />
          </div>
        </p>
      )}

      {/* Field 0: Payer country -smarty */}
      {(isFieldVisible('payerCountry') || iftiEnabled || isApolloCreditCard) && smartyEnabled && (
        <InputWithLabel
          label={getLabelforPayer('JS_PAYER_COUNTRY_LABEL')}
          required={isFieldMandatory('payerCountry') || iftiEnabled || isApolloCreditCard}
          tooltip={isDfTxn ? 'JS_PAYER_COUNTRY_LXNX_HINT' : ''}
        >
          <CountrySelect
            countries={allCountries}
            setCountry={fn2bCalledAfterCountrySet}
            value={formData['payerCountry']}
            disabled={validations['payerCountry']?.disableCondition}
            error={!!errors['payerCountry']}
          />
          {errors['payerCountry'] && (
            <FormHelperText error>{errors['payerCountry']}</FormHelperText>
          )}
        </InputWithLabel>
      )}

      {/* Field 1: Payer address */}
      {(isFieldVisible('payerAddress') || iftiEnabled || isApolloCreditCard) && (
        <>
          <InputWithLabel
            label={getLabelforPayer('JS_PAYER_ADDRESS_1_LABEL')}
            required={isFieldMandatory('payerAddress') || iftiEnabled || isApolloCreditCard}
            tooltip={isDfTxn ? 'JS_PAYER_ADDRESS_1_LXNX_HINT' : ''}
          >
            {!smartyEnabled && (
              <TextField
                label={''}
                name={'payerAddress'}
                value={formData['payerAddress']}
                variant='outlined'
                inputProps={{
                  placeholder: getLabelforPayer('JS_PAYER_ADDRESS_1_PLACEHOLDER'),
                  maxLength: 200,
                }}
                error={!!errors['payerAddress']}
                helperText={errors['payerAddress']}
                fullWidth
                disabled={validations['payerAddress']?.disableCondition}
                onChange={handleInputChange}
                onBlur={handleBlur}
              />
            )}

            {smartyEnabled && (
              <SmartAddress
                placeholder={getLabelforPayer('JS_PAYER_ADDRESS_1_PLACEHOLDER')}
                addressValue={formData['payerAddress']}
                label={''}
                country={formData['payerCountry']}
                setFormData={(data) => {
                  const updateData = {
                    payerCity: data.city,
                    payerState: data.state,
                    payerZip: data.zip,
                    payerAddress: data.address,
                    payerAddress2: data.address2,
                  };

                  Object.keys(updateData).forEach((key) => {
                    if (updateData[key] === undefined) {
                      delete updateData[key];
                    }
                  });

                  setFormData((prevState) => ({
                    ...prevState,
                    ...updateData,
                  }));
                  const addressError = updateData['payerAddress']
                    ? validateField('payerAddress', updateData['payerAddress'])
                    : '';
                  const address2Error = updateData['payerAddress2']
                    ? validateField('payerAddress2', updateData['payerAddress2'])
                    : '';
                  const cityError = updateData['payerCity']
                    ? validateField('payerCity', updateData['payerCity'])
                    : '';
                  const stateError = updateData['payerState']
                    ? validateField('payerState', updateData['payerState'])
                    : '';
                  setErrors({
                    ...errors,
                    payerAddress: addressError,
                    payerAddress2: address2Error,
                    payerCity: cityError,
                    payerState: stateError,
                  });
                }}
                formType={'payer'}
                error={!!errors['payerAddress']}
                helperText={errors['payerAddress']}
                onBlur={() => {
                  const addressError = validateField('payerAddress', formData['payerAddress']);
                  setErrors({
                    ...errors,
                    payerAddress: addressError,
                  });
                }}
              />
            )}
          </InputWithLabel>
        </>
      )}

      {/* Field 2: Payer address 2 */}
      {!isApolloCreditCard && isFieldVisible('payerAddress2') && (
        <InputWithLabel
          label={'JS_PAYER_ADDRESS_2_LABEL'}
          required={isFieldMandatory('payerAddress2')}
          tooltip={isDfTxn ? 'JS_PAYER_ADDRESS_2_LXNX_HINT' : ''} //TODO: Implement tooltip logic in InputWithLabel component
        >
          <TextField
            label={''}
            name={'payerAddress2'}
            value={formData['payerAddress2']}
            variant='outlined'
            inputProps={{
              placeholder: getLabelforPayer('JS_PAYER_ADDRESS_2_PLACEHOLDER'),
              maxLength: 40,
            }}
            disabled={validations['payerAddress2']?.disableCondition}
            error={!!errors['payerAddress2']}
            helperText={errors['payerAddress2']}
            fullWidth
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
        </InputWithLabel>
      )}

      {/* Field 3: Payer city */}
      {(isFieldVisible('payerCity') || iftiEnabled || isApolloCreditCard) && (
        <InputWithLabel
          label={getLabelforPayer('JS_PAYER_CITY_LABEL')}
          required={isFieldMandatory('payerCity') || iftiEnabled || isApolloCreditCard}
          tooltip={isDfTxn ? 'JS_PAYER_CITY_LXNX_HINT' : ''} //TODO: Implement tooltip logic in InputWithLabel component
        >
          <TextField
            label={''}
            name={'payerCity'}
            value={formData['payerCity']}
            variant='outlined'
            inputProps={{
              placeholder: getLabelforPayer('JS_PAYER_CITY_PLACEHOLDER'),
              maxLength: 40,
            }}
            disabled={validations['payerCity']?.disableCondition}
            error={!!errors['payerCity']}
            helperText={errors['payerCity']}
            fullWidth
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
        </InputWithLabel>
      )}

      {/* Field 4: Payer state */}
      {(isFieldVisible('payerState') || isApolloCreditCard) && (
        //   TODO: Add label null check in InputWithLabel component
        <InputWithLabel
          label={
            formData['payerCountry'] !== 'CAN' &&
            !(
              formData['payerCountry'] === 'CHN' &&
              model.selectedQuote.paymentType.defaultPspId === 'WorldPay'
            )
              ? getLabelforPayer('JS_PAYER_STATE_LABEL')
              : !isApolloCreditCard &&
                isFieldMandatory('payerState') &&
                (formData['payerCountry'] == 'CAN' ||
                  (formData['payerCountry'] === 'CHN' &&
                    model.selectedQuote.paymentType.defaultPspId === 'WorldPay'))
              ? getLabelForCaState('JS_PAYER_STATE_LABEL')
              : 'JS_PAYER_STATE_LABEL'
          }
          required={!isApolloCreditCard && (isFieldMandatory('payerState') || isGPXCaml())}
          tooltip={isDfTxn ? 'JS_PAYER_STATE_LXNX_HINT' : ''} //TODO: Implement tooltip logic in InputWithLabel component
        >
          {!['USA', 'CAN', 'MEX'].includes(formData.payerCountry) &&
          !(
            formData.payerCountry === 'CHN' &&
            (model.selectedQuote.paymentType.defaultPspId === 'WorldPay' ||
              model.selectedQuote.paymentType.defaultPspId === 'CurrencySelect')
          ) ? (
            <TextField
              label={''}
              name={'payerState'}
              value={formData['payerState']}
              variant='outlined'
              inputProps={{
                placeholder: getLabelforPayer('JS_PAYER_STATE_PLACEHOLDER'),
                maxLength: 40,
              }}
              disabled={validations['payerState']?.disableCondition}
              error={!!errors['payerState']}
              helperText={errors['payerState']}
              fullWidth
              onChange={handleInputChange}
              onBlur={handleBlur}
            />
          ) : (
            <>
              <FormControl
                fullWidth
                disabled={validations['payerState']?.disableCondition}
                error={!!errors['payerState']}
              >
                <Select
                  name='payerState'
                  value={formData['payerState']}
                  onChange={handleInputChange}
                >
                  {stateProvinces.map((option, index) => (
                    <MenuItem
                      key={index}
                      value={
                        model.selectedQuote.paymentType.defaultPspId === 'CurrencySelect' &&
                        formData.payerCountry !== 'USA'
                          ? option.isoCode
                          : option.name
                      }
                    >
                      {formData.payerCountry !== 'CHN' ? option.tName : option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors['payerState'] && (
                <FormHelperText error>{errors['payerState']}</FormHelperText>
              )}
            </>
          )}
        </InputWithLabel>
      )}

      {/* Field 5: Payer Zip */}
      {(isFieldVisible('payerZip') || isApolloCreditCard) && (
        <InputWithLabel
          label={getLabelforPayer('JS_PAYER_ZIP_LABEL')}
          required={
            (!isApolloCreditCard && isFieldMandatory('payerZip')) ||
            isUsAch() ||
            isUsOnlineBanking() ||
            (isGPXCan() && isGPXCaml())
          }
          tooltip={isDfTxn ? 'JS_PAYER_ZIP_LXNX_HINT' : ''} //TODO: Implement tooltip logic in InputWithLabel component
        >
          <TextField
            label={''}
            name={'payerZip'}
            value={formData['payerZip']}
            variant='outlined'
            inputProps={{
              placeholder: getLabelforPayer('JS_PAYER_ZIP_PLACEHOLDER'),
              maxLength: 35,
            }}
            disabled={validations['payerZip']?.disableCondition}
            error={!!errors['payerZip']}
            helperText={errors['payerZip']}
            fullWidth
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
        </InputWithLabel>
      )}

      {/* Field 6: Payer Country */}
      {(isFieldVisible('payerCountry') || iftiEnabled || isApolloCreditCard) && !smartyEnabled && (
        <InputWithLabel
          label={getLabelforPayer('JS_PAYER_COUNTRY_LABEL')}
          required={isFieldMandatory('payerCountry') || iftiEnabled || isApolloCreditCard}
          tooltip={isDfTxn ? 'JS_PAYER_COUNTRY_LXNX_HINT' : ''}
        >
          <CountrySelect
            countries={allCountries}
            setCountry={fn2bCalledAfterCountrySet}
            value={formData['payerCountry']}
            disabled={validations['payerCountry']?.disableCondition}
            error={!!errors['payerCountry']}
          />
          {errors['payerCountry'] && (
            <FormHelperText error>{errors['payerCountry']}</FormHelperText>
          )}
        </InputWithLabel>
      )}
    </div>
  );
};

export default PayerAddressSection;
