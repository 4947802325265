import truncate from 'html-truncate';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectRefDataCache } from '../../store/slices/refdata/refdataSlice';
import {
  selectDebtorPortalText,
  selectInitiatedFromGPFS,
} from '../../store/slices/session/sessionSlice';

const DebtorPortalTextDisplay = ({ type, unstyled }) => {
  const { cawtpHelpText, buyerDetailsHelpText, payerDetailsHelpText, introductionText } =
    useSelector(selectDebtorPortalText);
  const refDataCache = useSelector(selectRefDataCache);
  const [htmlString, setHtmlString] = useState('');
  const [limit, setLimit] = useState(null);
  const initiatedFromGPFS = useSelector(selectInitiatedFromGPFS);

  useEffect(() => {
    if (!initiatedFromGPFS) {
      return;
    }
    switch (type) {
      case 'SERVICES':
        setHtmlString(introductionText);
        //no limit
        break;
      case 'CAWTP':
        setHtmlString(cawtpHelpText);
        setLimit(getLimit('gpfs.cawtp.helptext.limit'));
        break;
      case 'BUYER':
        setHtmlString(buyerDetailsHelpText);
        setLimit(getLimit('gpfs.buyerdetails.helptext.limit'));
        break;
      case 'PAYER':
        setHtmlString(payerDetailsHelpText);
        setLimit(getLimit('gpfs.payerdetails.helptext.limit'));
        break;
      default:
        break;
    }
  }, [type, introductionText, cawtpHelpText, buyerDetailsHelpText, payerDetailsHelpText]);

  if (!initiatedFromGPFS) {
    return <></>;
  }

  const getLimit = (keyName) => {
    let configObj = refDataCache?.configList.find((e) => e.configKey === keyName);

    if (configObj) return parseInt(configObj.configValue);
  };

  if (!htmlString) return <></>;

  return (
    <div className={`my-3 ${unstyled ? '' : 'bg-warning p-3'}`}>
      <p dangerouslySetInnerHTML={{ __html: limit ? truncate(htmlString, limit) : htmlString }}></p>
    </div>
  );
};

export default DebtorPortalTextDisplay;
