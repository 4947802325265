import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { syncSession } from '../../apis';

import {
  ROUTE_MAPPING,
  hidePaymentSummaryAction,
  navigate,
  setHideUI,
  showPaymentSummaryAction,
} from '../../store/slices/router/routerSlice';
import {
  selectBankCountry,
  selectData,
  selectHideReceiptFlag,
  selectInitiatedFromGPFS,
  selectSsoRequest,
  setData,
  setPaymentFlowComplete,
  setTransactionStatus,
} from '../../store/slices/session/sessionSlice';
import { getSsoInfo } from '../../utils/helper';
import { OfflinePaymentInstruction } from '../OfflinePaymentInstruction';
import { MODES, STATUSES } from './constants';
import EmbeddedPayment from './embeddedPayment';
import { OrderReceipt } from './orderReceipt';
import PaymentPartnerCheckout from './paymentPartnerCheckout';
import AliPay from './paymentTypes/aliPay';
import CurrencySelect from './paymentTypes/currencySelect';
import FlutterWave from './paymentTypes/flutterWave';
import { KlarnaPayment } from './paymentTypes/klarna';
import TrsutlyNa from './paymentTypes/trsutlyNa';
import Unlimint from './paymentTypes/unlimint';
import WorldPay from './paymentTypes/worldpay';
import TransactionDisclosure from './transactionDisclosure';

export const PaymentPage = () => {
  const selectedBankCountry = useSelector(selectBankCountry);
  const session = useSelector(selectData);
  const hideReceiptPage = useSelector(selectHideReceiptFlag);
  const ssoRequest = useSelector(selectSsoRequest);
  const dispatch = useDispatch();
  const [mode, setMode] = useState('');
  const [extURL, setExtURL] = useState('');
  const initiatedFromGPFS = useSelector(selectInitiatedFromGPFS);

  useEffect(() => {
    dispatch(showPaymentSummaryAction());
    const remitterCountry = session.pledge?.payerCountry;
    const sellerCountry = session.sellerInfo.country;
    const serviceCountry = session.selectedServices.country;
    if (session.pledge.isSanctionedCountryPledge) {
      showReceipt(STATUSES.COMPLIANCE_FAILED);
    } else if (!session.selectedQuote.paymentType.onlinePayment) {
      if (session.petnetRedirectionUrl) {
        let paymentUrl = session.petnetRedirectionUrl;
        redirectExternal(paymentUrl);
        setMode(MODES.REDIRECT);
      } else if (session.selectedQuote.paymentType.id === 'WIRE') {
        if (serviceCountry === 'USA' || selectedBankCountry === 'USA') {
          if (sellerCountry !== 'USA') {
            setMode(MODES.DISCLOSURE); //Show transaction disclosure
          } else {
            setMode(MODES.OFFLINE); //show offline payment instructions
          }
        } else {
          setMode(MODES.OFFLINE); //show offline payment instructions
        }
      } else {
        setMode(MODES.OFFLINE); //show offline payment instructions
        checkForUnrender();
      }
    } else if (session.externalPaymentUrl) {
      let paymentUrl = session.externalPaymentUrl;
      redirectExternal(paymentUrl);
      setMode(MODES.REDIRECT);
    } else if (session.trustlySdkUrl) {
      if (serviceCountry === 'USA' || selectedBankCountry === 'USA') {
        if (sellerCountry !== 'USA') {
          setMode(MODES.DISCLOSURE); //Show transaction disclosure
        } else {
          setMode(MODES.TRUSTLY_NA);
        }
      } else {
        setMode(MODES.TRUSTLY_NA);
      }
    } else if (session.cohortGoRedirectUrl) {
      //PASS: as cohortgo is no longer used in prod.
    } else if (session.trustlyEmeaPaymentUrl) {
      let paymentUrl = session.trustlyEmeaPaymentUrl;
      redirectExternal(paymentUrl);
      setMode(MODES.REDIRECT);
    } else if (session.alipayQRCodeUrl) {
      setMode(MODES.ALIPAY);
    } else if (session.klarnaClientToken) {
      setMode(MODES.KLARNA);
    } else if (session.iciciRedirectUrl) {
      redirectExternal(session.iciciRedirectUrl);
      setMode(MODES.REDIRECT);
    } else {
      handleOtherCases(remitterCountry, sellerCountry);
    }
  }, []);

  const redirectExternal = (url) => {
    setExtURL(url);
    window.open(url, initiatedFromGPFS ? '_self' : '_blank');
  };

  const checkForUnrender = () => {
    if (hideReceiptPage) {
      dispatch(setHideUI(true));
      //If University returnUrl is present, redirect to that upon unrender
      const { returnUrl } = getSsoInfo(ssoRequest);

      if (returnUrl) {
        window.open(returnUrl, '_self');
      }

      return;
    }
  };

  const handleOtherCases = (remitterCountry, sellerCountry) => {
    let response = session.pledge;
    const paymentTypeId = session.selectedQuote.paymentType.id;
    const serviceCountry = session.selectedServices.country;

    if (response.embedded) {
      setMode(MODES.EMBEDDED);
    } else if (response.iframeIntegration) {
      if (serviceCountry === 'USA' || selectedBankCountry === 'USA') {
        if (sellerCountry !== 'USA') {
          setMode(MODES.DISCLOSURE);
        } else {
          gotoPspIntegration(paymentTypeId);
        }
      } else {
        gotoPspIntegration(paymentTypeId);
      }
    } else if (response.inline) {
      setMode(MODES.FLUTTERWAVE);
    } else if (
      ['APOLLO_CREDIT_VISA', 'APOLLO_CREDIT_MC', 'APOLLO_CREDIT_CARD'].includes(paymentTypeId)
    ) {
      if (remitterCountry === 'USA' && sellerCountry !== 'USA') {
        //PASS: Apollo payment types are no longer used
        // $state.go('gpfs.transactionDisclosure', {
        //   pspData: response.pspData,
        //   pspConnectionUrl: response.pspConnectionUrl,
        //   httpMethod: response.httpMethod,
        // });
      } else {
        //PASS: Apollo payment types are no longer used
        // setPspForwardDetails(response.pspData, response.pspConnectionUrl, response.httpMethod);
        // submitPspForm();
      }
    } else {
      /*
       *   For GEOSWIFT payment types we redirect to partner checkout and retrieve form submission details from state
       * */
      setMode(MODES.REDIRECT);
    }
  };

  const gotoPspIntegration = (paymentTypeId) => {
    switch (paymentTypeId) {
      case 'CS_MCP_MC':
      case 'CS_MCP_VISA':
      case 'CS_DCC_MC_VISA':
        setMode(MODES.CURRENCY_SELECT);
        break;
      case 'PIX':
      case 'NGN_BANK_TRANSFER':
        setMode(MODES.UNLIMINT);
        break;
      default:
        setMode(MODES.WORLD_PAY);
    }
  };

  const goToChoosePayments = () => {
    dispatch(navigate({ route: ROUTE_MAPPING.SERVICE_CATALOG }));
  };

  const showReceipt = async (status) => {
    checkForUnrender();
    dispatch(setPaymentFlowComplete(true));
    dispatch(hidePaymentSummaryAction());
    dispatch(setTransactionStatus(status));
    const response = await syncSession();
    if (response.success) {
      dispatch(setData(response.data));
      dispatch(navigate({ route: ROUTE_MAPPING.ORDER_RECEIPT }));
    }
  };

  switch (mode) {
    case MODES.RECEIPT:
      return <OrderReceipt />;
    case MODES.OFFLINE:
      dispatch(setPaymentFlowComplete(true));
      checkForUnrender();
      return <OfflinePaymentInstruction />;
    case MODES.DISCLOSURE:
      return (
        <TransactionDisclosure
          setMode={setMode}
          paymentTypeId={session.selectedQuote.paymentType.id}
          goToChoosePayments={goToChoosePayments}
        />
      );
    case MODES.WORLD_PAY:
      return (
        <WorldPay
          iframeUrl={session.worldPayIframe}
          locale={session.worldpayLocale}
          showReceipt={showReceipt}
          goToChoosePayments={goToChoosePayments}
        />
      );
    case MODES.CURRENCY_SELECT:
      return (
        <CurrencySelect
          iframeUrl={session.worldPayIframe}
          showReceipt={showReceipt}
          goToChoosePayments={goToChoosePayments}
        />
      );
    case MODES.UNLIMINT:
      return (
        <Unlimint
          iframeUrl={session.worldPayIframe}
          showReceipt={showReceipt}
          goToChoosePayments={goToChoosePayments}
        />
      );
    case MODES.FLUTTERWAVE:
      return (
        <FlutterWave
          session={session}
          showReceipt={showReceipt}
          goToChoosePayments={goToChoosePayments}
        />
      );
    case MODES.ALIPAY:
      return <AliPay QRCodeURL={session.alipayQRCodeUrl} showReceipt={showReceipt} />;
    case MODES.TRUSTLY_NA:
      return (
        <TrsutlyNa
          trustlySdkUrl={session.trustlySdkUrl}
          trustlyDataView={session.trustlyDataView}
          showReceipt={showReceipt}
          goToChoosePayments={goToChoosePayments}
        />
      );
    case MODES.REDIRECT:
      return (
        <PaymentPartnerCheckout
          session={session}
          showReceipt={showReceipt}
          extURL={extURL}
          starPolling={!initiatedFromGPFS}
          showMessage={!initiatedFromGPFS}
          pspFormOnTarget={initiatedFromGPFS ? '_self' : '_target'}
        />
      );
    case MODES.EMBEDDED:
      return (
        <EmbeddedPayment
          pspURL={session?.pledge?.pspConnectionUrl}
          sellerName={session.sellerInfo.name}
          hideReceiptPage={hideReceiptPage}
          checkForUnrender={checkForUnrender}
        />
      );
    case MODES.KLARNA: //
      return <KlarnaPayment session={session} showReceipt={showReceipt} />;
    default:
      return <></>;
  }
};
