import { ThemeProvider } from '@emotion/react';
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import AppRouter from './AppRouter';
import { getServiceDetails, getToken, syncSession } from './apis';
import I18nDataProvider from './i18n/I18nDataProvider';
import store from './store';
import { initRefdata } from './store/slices/refdata/action';
import {
  activateRoutes,
  hidePaymentSummaryAction,
  navigate,
  ROUTE_MAPPING,
  setRoutes,
  setShowStepper,
} from './store/slices/router/routerSlice';
import {
  setData,
  setDispayParentLogo,
  setGpfsData,
  setHideReceipt,
  setHideServiceCatalog,
  setHomeAmount,
  setHomeCurrency,
  setIsGPXClient,
  setIsPaymentEventEnabled,
  setLocale,
  setSellerId,
  setShowReference,
  setSpmUIDisplay,
  setSsoRequest,
  setToken,
  setTrackingDisplay,
} from './store/slices/session/sessionSlice';
import { injectStore } from './utils/apiClient';
import { getSsoInfo, getTotalAmount, parseBoolean } from './utils/helper';

injectStore(store);

const App = ({ sellerInfo, theme, themeObject, locale, isPaymentEventEnabled }) => {
  const [_locale, _setLocale] = useState('');

  useEffect(() => {
    store.dispatch(setIsPaymentEventEnabled(isPaymentEventEnabled));
    if (!store.getState().session.paymentFlowComplete) initialize();
  }, []);

  const initialize = async () => {
    const { merchantId, merchantSecret, sellerId, reqLocale, buyerId, gpfsData } =
      getSsoInfo(sellerInfo);
    const finalLocale = locale ?? reqLocale;

    _setLocale(finalLocale);
    store.dispatch(setLocale(finalLocale));
    if (sellerId || gpfsData?.gpfsClient)
      store.dispatch(setSellerId(sellerId || gpfsData?.gpfsClient));

    store.dispatch(setSsoRequest(sellerInfo));
    store.dispatch(setGpfsData(gpfsData));

    if (gpfsData.showReceiptForRedirectPayments) {
      /* To show reciepts for redirect payments, gpfs redirect payments are opened in same tab */
      const response = await syncSession();
      if (response.success) {
        store.dispatch(setData(response.data));
      }
      store.dispatch(hidePaymentSummaryAction(true));
      store.dispatch(
        setRoutes({
          hideServiceCatalog: false,
          consolBuyerRemitterPageEnabled: gpfsData.consolBuyerRemitterPageEnabled,
          hideReceiptPage: false,
          enableLanding: false,
        }),
      );
      store.dispatch(setShowStepper(true));
      store.dispatch(initRefdata());
      store.dispatch(navigate({ route: ROUTE_MAPPING.ORDER_RECEIPT }));
      store.dispatch(activateRoutes(true));
      return;
    }

    const response = await getToken({ sellerId, merchantId, merchantSecret, buyerId });
    if (!response.success) return;

    const { Authorization, ...accessResponse } = response.data;
    const token = Authorization[0];
    store.dispatch(setToken(token));

    const hideServiceCatalog = parseBoolean('hideServiceCatalogue', accessResponse);
    const hideReceiptPage = parseBoolean('hideReceiptPage', accessResponse);
    const displayTracking = parseBoolean('displayTracking', accessResponse);
    const showReference = parseBoolean('showReference', accessResponse);
    const displayParentLogo = parseBoolean('displayParentLogo', accessResponse);
    const enableLanding = parseBoolean('displayLandingPage', accessResponse);
    const enableSPMUI = parseBoolean('isSpmUiEnabled', accessResponse);
    let consolBuyerRemitterPageEnabled = false;

    if (!enableLanding && enableSPMUI) {
      const serviceResponse = (await getServiceDetails(sellerInfo)).data.data;
      store.dispatch(setData(serviceResponse));

      if (hideServiceCatalog) {
        store.dispatch(setHideServiceCatalog(true));
        const serviceItems = (serviceResponse.serviceItemViewList?.serviceItemViews || []).map(
          (item) => ({
            ...item,
            included: true,
          }),
        );

        store.dispatch(setIsGPXClient(serviceResponse.sellerInfo.sellerIndustry !== 'EDUCATION'));
        store.dispatch(setHomeAmount(getTotalAmount(serviceItems)));
        store.dispatch(setHomeCurrency(serviceResponse.sellerInfo?.homeCurrency));
      }
      consolBuyerRemitterPageEnabled = serviceResponse.sellerInfo.consolBuyerRemitterPageEnabled;
    }

    store.dispatch(
      setRoutes({
        hideServiceCatalog,
        consolBuyerRemitterPageEnabled:
          consolBuyerRemitterPageEnabled || gpfsData.consolBuyerRemitterPageEnabled,
        hideReceiptPage,
        enableLanding: enableLanding && !gpfsData.initiatedFromGPFS,
      }),
    );

    if (hideServiceCatalog) store.dispatch(navigate({ route: ROUTE_MAPPING.PAYMENT_METHOD }));

    store.dispatch(setShowStepper(true));
    store.dispatch(setHideReceipt(hideReceiptPage));
    store.dispatch(setTrackingDisplay(displayTracking && !gpfsData.initiatedFromGPFS));
    store.dispatch(setSpmUIDisplay(enableSPMUI));
    store.dispatch(setDispayParentLogo(displayParentLogo));
    store.dispatch(setShowReference(showReference));
    store.dispatch(initRefdata());
    store.dispatch(activateRoutes(true));
  };

  return (
    <Provider store={store}>
      {_locale && (
        <I18nDataProvider>
          <ThemeProvider theme={theme}>
            <AppRouter themeObject={themeObject} />
          </ThemeProvider>
        </I18nDataProvider>
      )}
    </Provider>
  );
};

export default App;
