import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDynamicColumns } from '../../hooks/responsiveHook';
import { Translate } from '../../i18n/translate';
import {
  ROUTE_MAPPING,
  navigateBack,
  routeIndex,
  routeName,
  selectHidePaymentSummary,
} from '../../store/slices/router/routerSlice';
import {
  selectData,
  selectHideServiceCatalog,
  selectHomeAmount,
  selectHomeCurrency,
  selectPaymentFlowComplete,
  selectQuote,
} from '../../store/slices/session/sessionSlice';
import { eventBus } from '../../utils/events/eventBus';
import { EPS_EVENT } from '../../utils/events/eventTypes';
import { Cf, Nf } from '../Format';

const backButtonName = {};
backButtonName[ROUTE_MAPPING.SERVICE_CATALOG] = 'JS_EDIT_UNIVERSITY';
backButtonName[ROUTE_MAPPING.PAYMENT_METHOD] = 'JS_EDIT_QUOTE';
backButtonName[ROUTE_MAPPING.BUYER_FROM] = 'JS_EDIT_PAYMENT_DETAILS';
backButtonName[ROUTE_MAPPING.COSOLIDATED] = 'JS_EDIT_PAYMENT_DETAILS';
backButtonName[ROUTE_MAPPING.PAYER_FORM] = 'JS_EDIT_RECEIVER_DETAILS';
backButtonName[ROUTE_MAPPING.PAYMENT] = 'JS_EDIT_PAYER_DETAILS';

const PaymentSummary = () => {
  const routerIndex = useSelector(routeIndex);
  const route = useSelector(routeName);
  const hidePaymentSummary = useSelector(selectHidePaymentSummary);
  const paymentFlowComplete = useSelector(selectPaymentFlowComplete);
  const dispatch = useDispatch();
  const homeAmount = useSelector(selectHomeAmount);
  const homeCurrency = useSelector(selectHomeCurrency);
  const selectedQuote = useSelector(selectQuote);
  const session = useSelector(selectData);
  const containerRef = useRef(null);
  const columns = useDynamicColumns(1, containerRef, 390);
  const hideServiceCatalog = useSelector(selectHideServiceCatalog);

  const goBack = () => {
    dispatch(navigateBack());
    if (route == ROUTE_MAPPING.SERVICE_CATALOG) {
      eventBus.emit(EPS_EVENT.GPFS_LANDING, {
        source: 'EPS_CONVERA',
        eventType: EPS_EVENT.GPFS_LANDING,
      });
    }
  };

  if (hidePaymentSummary || paymentFlowComplete || route === ROUTE_MAPPING.LANDING) {
    return <></>;
  }

  return (
    <div className='pt-3 pb-3  max-w-[750px] m-auto' ref={containerRef}>
      <div>
        <p
          onClick={() => goBack()}
          className={`${columns == 2 ? 'text-center cursor-pointer' : ''}`}
        >
          {!(hideServiceCatalog && route == ROUTE_MAPPING.PAYMENT_METHOD) &&
            !(route == ROUTE_MAPPING.PAYMENT) && (
              <>
                <ArrowBackIcon className='text-accent mr-1' />
                <span className='text-accent'>
                  <Translate value={backButtonName[route]} />
                </span>
              </>
            )}
        </p>
        {route != ROUTE_MAPPING.SERVICE_CATALOG && (
          <h4 className='text-16-bold mt-3'>
            <Translate value={'JS_PAYMENT_SUMMARY'} />
          </h4>
        )}
        {route != ROUTE_MAPPING.SERVICE_CATALOG && (
          <div>
            {routerIndex >= 2 && selectedQuote && (
              <p className='border-b border-b-gray4 pt-3'>
                <span className='text-base'>
                  <Translate value={'JS_YOU_ARE_PAYING'} />
                </span>
                <span className='text-16 font-mono float-right'>
                  {Nf(selectedQuote.buyerAmount)} {Cf(selectedQuote.buyerCurrency)}
                </span>
              </p>
            )}
            {homeAmount > 0 && (
              <p className='border-b border-b-gray4 pt-3'>
                <span className='text-base'>
                  <Translate value={'JS_INSTITUTION_WILL_RECEIVE'} />
                </span>
                <span className='text-16 font-mono float-right'>
                  {Nf(homeAmount)} {Cf(homeCurrency)}
                </span>
              </p>
            )}
            {session.ecbDifference && (
              <p className='border-b border-b-gray4 pt-3'>
                <span className='text-base'>
                  <Translate value={'JS_ECB_DIFF'} />
                </span>
                <span className='text-16 font-mono float-right'>
                  {parseFloat(session.ecbDifference).toFixed(2)}%
                </span>
              </p>
            )}
          </div>
        )}
        {routerIndex == 4 && (
          <div className='mt-3'>
            <h4 className='text-16-bold'>
              <Translate value={'JS_STUDENT_DETAILS'} />
            </h4>
            <div>
              <p className='border-b border-b-gray4 pt-3'>
                <span className='text-base'>
                  <Translate value={'JS_NAME'} />
                </span>
                <span className='text-14 float-right'>
                  {session.buyerDetails?.firstName} {session.buyerDetails?.lastName}
                </span>
              </p>
              <p className='border-b border-b-gray4 pt-3'>
                <span className='text-base'>
                  {' '}
                  <Translate value={'JS_EMAIL'} />
                </span>
                <span className='text-14 float-right'>{session.buyerDetails.email}</span>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentSummary;
