import { createSlice } from '@reduxjs/toolkit';
import { getReturnUrlConfig } from '../../../utils/helper';
import { checkTokenExpiry } from '../../../utils/tokenExpirty';

const tokenSlice = createSlice({
  name: 'session',
  initialState: {
    token: '',
    hideReceiptPage: false,
    dispayParentLogo: false,
    displayTracking: false,
    spmUIDisplay: true,
    locale: '',
    payerCountry: '',
    homeAmount: 0,
    homeCurrency: '',
    sellerId: '',
    error: null,
    ssoRequest: '',
    catalog: null,
    data: null,
    loading: false,
    selectedQuote: null,
    transactionStatus: '',
    paymentFlowComplete: false,
    isGPXClient: false,
    hideServiceCatalog: false,
    showLandingPage: false,
    consolBuyerRemitterPageEnabled: false,
    selectedBankCountry: '',
    isPaymentEventEnabled: false,
    showReference: false,
    gpfsData: {
      gpfsClient: '',
      initiatedFromGPFS: false,
      showReceiptForRedirectPayments: false,
      ssoPayload: '',
    },
    debtorPortalText: {},
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      checkTokenExpiry(action.payload);
      if (state.gpfsData.initiatedFromGPFS) {
        sessionStorage.setItem('eps_token', action.payload);
      } else {
        sessionStorage.removeItem('eps_token');
      }
    },
    setHideReceipt: (state, action) => {
      state.hideReceiptPage = action.payload;
    },
    setTrackingDisplay: (state, action) => {
      state.displayTracking = action.payload;
    },
    setSpmUIDisplay: (state, action) => {
      state.spmUIDisplay = action.payload;
    },
    setDispayParentLogo: (state, action) => {
      state.dispayParentLogo = action.payload;
    },
    setBankCountry: (state, action) => {
      state.selectedBankCountry = action.payload;
    },
    removeToken: (state) => {
      state.token = '';
    },
    setLocale: (state, action) => {
      state.locale = action.payload;
    },

    setSellerId: (state, action) => {
      state.sellerId = action.payload;
    },

    setPayerCountry: (state, action) => {
      state.payerCountry = action.payload;
    },

    setHomeAmount: (state, action) => {
      state.homeAmount = action.payload;
    },
    setHomeCurrency: (state, action) => {
      state.homeCurrency = action.payload;
    },

    setError: (state, action) => {
      state.error = action.payload;
    },

    setSsoRequest: (state, action) => {
      state.ssoRequest = action.payload;
    },

    setCatalog: (state, action) => {
      state.catalog = action.payload;
    },
    setData: (state, action) => {
      const incomingData = action.payload;
      let updatedData = incomingData;

      if (incomingData && incomingData.sellerInfo) {
        state.consolBuyerRemitterPageEnabled =
          incomingData.sellerInfo.consolBuyerRemitterPageEnabled;
      }

      if (state.gpfsData.initiatedFromGPFS) {
        const { returnUrl, enableReturn } = getReturnUrlConfig(incomingData);
        if (enableReturn) {
          updatedData = { ...incomingData, returnUrl };
        }
      }
      state.data = updatedData;
    },
    setSelectedQuote: (state, action) => {
      state.selectedQuote = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setTransactionStatus: (state, action) => {
      state.transactionStatus = action.payload;
    },
    setPaymentFlowComplete: (state, action) => {
      state.paymentFlowComplete = action.payload;
    },
    setIsGPXClient: (state, action) => {
      state.isGPXClient = action.payload;
    },
    setHideServiceCatalog: (state, action) => {
      state.hideServiceCatalog = action.payload;
    },

    setShowLandingPage: (state, action) => {
      state.showLandingPage = action.payload;
    },

    setIsPaymentEventEnabled: (state, action) => {
      state.isPaymentEventEnabled = action.payload;
    },

    setShowReference: (state, action) => {
      state.showReference = action.payload;
    },

    setGpfsData: (state, action) => {
      state.gpfsData = { ...state.gpfsData, ...action.payload };
      if (state.gpfsData.initiatedFromGPFS && state.gpfsData.receiptPath) {
        sessionStorage.setItem('receipt-path', state.gpfsData.receiptPath);
      }
      if (!state.gpfsData.initiatedFromGPFS) {
        sessionStorage.removeItem('receipt-path');
      }
    },
    setDebtorPortalText: (state, action) => {
      state.debtorPortalText = {
        ...state.debtorPortalText,
        ...action.payload,
      };
    },
  },
});

export const {
  setToken,
  setHideReceipt,
  setTrackingDisplay,
  setDispayParentLogo,
  setBankCountry,
  removeToken,
  setLocale,
  setPayerCountry,
  setHomeAmount,
  setHomeCurrency,
  setSellerId,
  setError,
  setCatalog,
  setSsoRequest,
  setData,
  setLoading,
  setSelectedQuote,
  setTransactionStatus,
  setPaymentFlowComplete,
  setIsGPXClient,
  setHideServiceCatalog,
  setShowLandingPage,
  setIsPaymentEventEnabled,
  setShowReference,
  setGpfsData,
  setDebtorPortalText,
  setSpmUIDisplay,
} = tokenSlice.actions;
export const selectLocale = (state) => state.session.locale;
export const selectHideReceiptFlag = (state) => state.session.hideReceiptPage;
export const selectTrackingDisplay = (state) => state.session.displayTracking;
export const selectDispayParentLogo = (state) => state.session.dispayParentLogo;
export const selectPayerCountry = (state) => state.session.payerCountry;
export const selectHomeAmount = (state) => state.session.homeAmount;
export const selectHomeCurrency = (state) => state.session.homeCurrency;
export const selectSellerId = (state) => state.session.sellerId;
export const selectToken = (state) => state.session.token;
export const selectError = (state) => state.session.error;
export const selectBankCountry = (state) => state.session.selectedBankCountry;
export const selectService = (state) => state.session.service;
export const selectSsoRequest = (state) => state.session.ssoRequest;
export const selectCatalog = (state) => state.session.catalog;
export const selectData = (state) => state.session.data;
export const selectLoading = (state) => state.session.loading;
export const selectQuote = (state) => state.session.data?.selectedQuote;
export const selectTransactionStatus = (state) => state.session.transactionStatus;
export const selectPaymentFlowComplete = (state) => state.session.paymentFlowComplete;
export const selectIsGPXClient = (state) => state.session.isGPXClient;
export const selectHideServiceCatalog = (state) => state.session.hideServiceCatalog;
export const selectConsolBuyerRemitterPageEnabled = (state) =>
  state.session.consolBuyerRemitterPageEnabled;
export const selectIsPaymentEventEnabled = (state) => state.session.isPaymentEventEnabled;
export const selectShowLandingPage = (state) => state.session.showLandingPage;
export const selectShowReference = (state) => state.session.showReference;
export const selectInitiatedFromGPFS = (state) => state.session.gpfsData.initiatedFromGPFS;
export const selectGpfsClient = (state) => state.session.gpfsData.gpfsClient;
export const selectShowReceiptForRedirectPaymentsGpfs = (state) =>
  state.session.gpfsData.showReceiptForRedirectPayments;
export const selectDebtorPortalText = (state) => state.session.debtorPortalText;
export const selectGpfsSsoPayload = (state) => state.session.gpfsData.ssoPayload;
export const selectSpmUIDsiplay = (state) => state.session.spmUIDisplay;
export default tokenSlice.reducer;
